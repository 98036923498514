@import url('../../styles/customMediaQueries.css');

.root {
  display: grid;
  gap: var(--n-size-3);
}

.title {
  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-2);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.fieldsContainer {
  display: grid;
  gap: var(--n-size-2);
}

.fieldsContainer--signup {
  @media (width >= 768px) {
    grid-template-columns: 1fr 1fr;

    & > *:first-child,
    & > *:nth-child(2) {
      grid-column: 1 / -1;
    }
  }
}

.fieldsContainer--socialConfirm {
  @media (width >= 768px) {
    grid-template-columns: 1fr 1fr;

    & > *:first-child {
      grid-column: 1 / -1;
    }
  }
}

.idpButtons {
  display: grid;
  gap: var(--n-size-2);
}

.idpButtons button {
  align-self: center;

  width: 100%;

  color: black;

  background: transparent;
  border: 1px solid var(--n-color-text-subtle);
  border-radius: var(--radius-full);

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background: #f5f7fa;
  }

  & svg {
    stroke: var(--n-color-black);
  }
}

.divider {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: var(--n-size-1);
}

.dividerLine {
  height: 1px;
  margin-top: 11px;
  background: var(--n-color-border-active);
}

.dividerText {
  /* Body/Body 1/Regural */
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--n-color-text-subtle);
  text-align: center;
}

.logIn {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 500;
  color: var(--n-color-text-dark);
  text-align: center;
}

.logIn a,
.link {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-00);
  font-weight: 600;
  color: var(--n-color-text-dark);
  text-decoration-line: underline;
}

.agreeToTnc {
  margin: calc(-1 * var(--n-size-1)) 0;

  font-size: var(--n-font-size-00);
  font-weight: 400;
  color: var(--n-color-text-dark);
  text-align: center;

  & a {
    text-decoration: underline;
  }
}

.marketingConsentCheckbox {
  grid-column: 1 / -1;
  padding: 6px;
}
