.root {
  display: grid;
  gap: var(--n-size-3);
  text-align: center;
}

.root h1 {
  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-3);
  text-transform: uppercase;
}

.root p {
  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-1);
}

.linkButton {
  padding: 0;

  font-family: Montserrat, sans-serif;
  text-decoration: underline;
  text-transform: none;

  border: 0;
}

.error {
  color: var(--colorFail);
}
