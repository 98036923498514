@import url('../../styles/customMediaQueries.css');

.contentWrapper {
  isolation: isolate;
  position: relative;
  overflow: hidden;
  display: grid;

  @media (width >= 768px) {
    place-content: center;
    align-items: start;
  }

  @media (width >= 1024px) {
    grid-template-columns: auto auto;
    gap: var(--n-size-4);
    padding: var(--n-size-4);
  }
}

.textParagraph {
  z-index: 1;

  display: grid;

  max-width: 600px;
  padding: var(--n-size-4);
  padding-top: var(--n-size-9);

  font-family: Syne, sans-serif;
  font-size: var(--n-font-size-2);
  color: var(--n-color-black);
  text-align: center;

  & p {
    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-4);
    font-weight: 700;
    line-height: 1.3em;
    text-transform: uppercase;
  }

  & span {
    margin-top: var(--n-size-3);
  }

  @media (width >= 768px) {
    & p {
      font-size: var(--n-font-size-5);
    }
  }
}

.backgroundImage {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: 52.5% 70%;

  @media (width >= 1024px) {
    object-position: 50% 85%;
  }
}

.content {
  z-index: 1;

  display: grid;
  align-content: center;

  padding: var(--n-size-4);

  background: white;

  @media (width >= 768px) {
    width: calc(600px + 2 * var(--n-size-4));
    border-radius: var(--radius-xxl);
  }
}

.welcomeButtons {
  display: grid;
  gap: var(--n-size-2);
  justify-items: center;

  width: 100%;
  padding: 0 var(--n-size-4);

  & * {
    width: 100%;
    max-width: 240px;
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  gap: 0;
  width: 100%;
}

.tab {
  composes: marketplaceModalTitleStyles from global;

  margin: 0;
  padding: 0;

  font-family: Montserrat;
  font-size: var(--n-font-size-0);
  font-weight: 400;
  line-height: 20px;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.form {
  margin-top: var(--n-size-3);

  @media (width >= 768px) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  flex-basis: 260px;

  /* margin-top: var(--n-size-3); */

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;

  @media (width >= 768px) {
    flex-basis: 330px;

    /* margin-top: 30px; */
    padding-top: 2px;
  }
}

.signupForm {
  flex-basis: 340px;

  /* margin-top: var(--n-size-3); */

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;

  @media (width >= 768px) {
    flex-basis: 410px;

    /* margin-top: 30px; */
    padding-top: 2px;
  }
}

/* ================ Typography ================ */

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
  hyphens: auto;

  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
}

/* ================ Errors ================ */

.error {
  color: var(--colorFail);
}
